import "./ErrorPage.css"
const ErrorPage = () =>{
return <div class="error">
    <h1>Error 404:
        Page not Found
    </h1>
    

    <a href=".." class="error-link"> Homepage </a>

</div>
}
export default ErrorPage;
